import Link from "next/link";
import { useRouter } from "next/router";

function SwitchLang() {
  const { locale, asPath } = useRouter();
  return (
    <div className="group absolute right-4 top-4 flex space-x-4 text-slate-300 hover:cursor-pointer">
      {locale === "es" && (
        <Link
          href={asPath}
          locale="en"
          className="hover:text-sky-500"
          aria-label="Switch language to english"
        >
          English
        </Link>
      )}

      {locale === "en" && (
        <Link
          href={asPath}
          locale="es"
          className="hover:text-sky-500"
          aria-label="Cambiar idioma a español"
        >
          Español
        </Link>
      )}
    </div>
  );
}

export default SwitchLang;
