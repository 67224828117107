import Link from "next/link";
import { useRouter } from "next/router";
import Logo from "../Logo";

function Footer() {
  const { locale } = useRouter();
  return (
    <footer className="mx-auto mt-16 w-full max-w-screen-2xl text-sm md:text-base">
      <hr className="border-gray-700" />
      <div className="flex flex-col justify-center space-y-6 p-8 text-gray-400 md:flex-row md:space-x-12 md:space-y-0">
        {/* <Link
          href="#"
          aria-label={locale === "en" ? "About Us" : "Sobre nosotros"}
          className="mx-auto hover:text-sky-600 md:mx-0"
        >
          {locale === "en" ? "About Us" : "Sobre nosotros"}
        </Link>
        <Link
          href="#"
          aria-label={
            locale === "en" ? "Privacy Policy" : "Política de privacidad"
          }
          className="mx-auto hover:text-sky-600 md:mx-0"
        >
          {locale === "en" ? "Privacy Policy" : "Política de privacidad"}
        </Link>
        <Link
          href="#"
          aria-label={locale === "en" ? "Terms of use" : "Términos de uso"}
          className="mx-auto hover:text-sky-600 md:mx-0"
        >
          {locale === "en" ? "Terms of use" : "Términos de uso"}
        </Link> */}
        <div className="m-auto flex items-center gap-4 p-8 text-center text-gray-500">
          © 2023 treegrow.dev{" "}
          <Link
            href="/"
            aria-label={
              locale === "en" ? "Go home footer" : "Ir al inicio pie de pagina"
            }
          >
            <Logo className="h-4 w-4" />
          </Link>
        </div>
      </div>
      {/*  <div className="flex flex-col gap-1">
        <hr className="border-gray-600" />
        <hr className="border-gray-600" />
        <hr className="border-gray-600" />
        <hr className="border-gray-600" />
        <hr className="border-gray-600" />
        <hr className="border-gray-600" />
      </div> */}
    </footer>
  );
}

export default Footer;
