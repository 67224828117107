import { type ReactNode } from "react";

/* const animation = {
  hidden: {
    scaleX: "0",
    scale: 0,
    opacity: 0,
  },
  visible: {
    scaleX: "1",
    scale: 1,
    opacity: 1,
  },
  exit: {
    scale: 0,
    opacity: 0,
  },
}; */

type Props = {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
  onClickOutside: (event: React.MouseEvent<HTMLElement>) => void;
};

function Modal({ onClickOutside, children, className }: Props) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClickOutside(e);
      }}
      className="fixed inset-0 z-40 flex justify-center bg-black/30 backdrop-blur-sm"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`z-50 mt-auto rounded-lg border border-gray-700 bg-gray-900 shadow-lg ${className}`}
      >
        {children}
      </div>
    </div>
  );
}

export default Modal;
