/* eslint-disable */

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

function getColorByTag(tag: any) {
  let formattedTag = tag;

  if (tag[1] && tag[1].props && tag[1].props.textToHighlight) {
    formattedTag = tag[1].props.textToHighlight;
  }

  formattedTag = formattedTag
    .toString()
    .replace("#", "")
    .replaceAll(",", "")
    .trim();

  switch (formattedTag) {
    case "javascript":
      return "#f0db4f";
    case "react":
      return "#61dafb";
  }
}
function Badge({ children, onClick }: Props) {
  return (
    <div onClick={onClick} className="group relative w-fit">
      <div
        className={`absolute -inset-[1px] opacity-5 `}
        style={{ backgroundColor: getColorByTag(children as string) }}
      ></div>
      <div className={`relative flex  px-2 py-1`}>
        <div>
          <p
            className={`text-[0.7rem] font-semibold text-${getColorByTag(
              children as string,
            )} md:text-xs`}
            style={{ color: getColorByTag(children as string) }}
          >
            {children}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Badge;
