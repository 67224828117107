import { useEffect, useRef, useState } from "react";
import { type Article, allArticles } from "contentlayer/generated";
import { compareDesc } from "date-fns";
import { getLogoByTagName } from "@/utils/logos";
import Highlighter from "react-highlight-words";

import Link from "next/link";
import Modal from "@/components/Modal";
import { useRouter } from "next/router";
import { removeAccents } from "@/utils/strings";
import Badge from "./Badge";

function SearchBox() {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const searchInput = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [articles, setArticles] = useState<Article[]>([]);
  const { locale } = useRouter();

  const highligtText = (text: string) => (
    <Highlighter
      searchWords={searchTerm.trim().toLowerCase().split(/\s+/)}
      autoEscape={true}
      sanitize={removeAccents}
      textToHighlight={text}
    />
  );

  const onModalClose = () => {
    setIsSearchModalOpen(false);
    setSearchTerm("");
  };

  useEffect(() => {
    if (isSearchModalOpen) {
      if (searchInput.current) searchInput.current.focus();
    }
  }, [isSearchModalOpen]);

  useEffect(() => {
    // Split the search term into an array of lowercase search terms
    const searchTerms = searchTerm.trim().toLowerCase().split(/\s+/);

    // Filter articles by locale
    const filteredByLocale = allArticles.filter(
      (article) => article.locale === locale,
    );

    // Function to check if a term is present in a string (case-insensitive)
    const containsTerm = (str: string, term: string) =>
      removeAccents(str.toLowerCase()).includes(term.trim()) ||
      str.toLowerCase().includes(term.trim());

    // Filter articles based on the presence of all search terms in any order
    const filteredArticles = filteredByLocale.filter((article) =>
      searchTerms.every(
        (term) =>
          containsTerm(article.title, term) ||
          containsTerm(article.subtitle, term) ||
          article.tags.some((tag) => `#${tag}`.toLowerCase().includes(term)),
      ),
    );

    // Sort the filtered articles by date in descending order
    const sortedArticles = filteredArticles.sort((a, b) =>
      compareDesc(new Date(b.date), new Date(a.date)),
    );

    setArticles(sortedArticles);
  }, [searchTerm, locale]);

  return (
    <>
      <div
        onClick={() => setIsSearchModalOpen(true)}
        className="group mx-auto mt-20 flex w-[80%] max-w-[386px] space-x-3 rounded-lg border border-gray-700 bg-gray-800 p-2 shadow-lg hover:cursor-pointer hover:bg-gray-700 dark:hover:border-sky-600 md:w-96"
      >
        <span className="ml-2"> 🔎 </span>
        <div className="w-full bg-gray-800 text-gray-400 placeholder-gray-400 outline-none group-hover:cursor-pointer group-hover:bg-gray-700">
          {locale === "en"
            ? "Explore the knowledge...✍🏼"
            : "Explora el conocimiento...✍🏼"}
        </div>
      </div>

      {/* Search modal */}
      {isSearchModalOpen && (
        <Modal
          className="mb-20 flex h-3/4 w-[85%] flex-col overflow-auto p-4 md:w-2/4"
          isOpen={isSearchModalOpen}
          onClickOutside={() => onModalClose()}
        >
          <div
            onClick={() => searchInput.current?.focus()}
            className="mx-auto flex w-full space-x-2 rounded-lg border border-gray-700 bg-gray-800  p-2 shadow-lg"
          >
            <span className="ml-2"> 🔎 </span>
            <input
              autoFocus={true}
              ref={searchInput}
              className="w-full bg-gray-800 text-gray-300 placeholder-gray-400 outline-none"
              type="text"
              onInput={(e) =>
                setSearchTerm((e.target as HTMLInputElement).value)
              }
              value={searchTerm}
              placeholder={
                locale === "en"
                  ? "What do you want to learn?"
                  : "¿Qué quieres aprender?"
              }
            />
          </div>
          <div className="mt-2 flex w-full flex-col gap-3 rounded-md bg-gray-900">
            {articles.map(({ title, tags, url, subtitle }, idx) => (
              <Link
                onClick={() => onModalClose()}
                key={idx}
                href={url}
                className="group flex min-h-[6rem] items-center justify-start gap-6 break-words rounded-md border border-gray-600 bg-gray-900 p-4 text-gray-300 hover:cursor-pointer hover:bg-gray-800"
              >
                {tags[0] &&
                  getLogoByTagName(tags[0], "md:h-12 md:w-12 h-8 w-8")}
                <div className="flex flex-col">
                  <span className="my-auto text-base font-bold group-hover:text-sky-500 md:text-lg">
                    {highligtText(title.replaceAll("\\n", ""))}
                  </span>
                  <span className="md:text-md my-auto text-sm font-light">
                    {highligtText(subtitle)}
                  </span>
                  <div className="mt-2 flex gap-2">
                    {tags.map((tag) => (
                      <Badge key={tag}> {highligtText(`#${tag} `)} </Badge>
                    ))}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
}

export default SearchBox;
