import dynamic from "next/dynamic";

const JavascriptLogo = dynamic(
  () => import("@/components/TechLogos/Javascript"),
);
const ReactLogo = dynamic(() => import("@/components/TechLogos/React"));

export function getLogoByTagName(tag: string, className?: string) {
  switch (tag) {
    case "react":
      return <ReactLogo className={className} />;
    case "javascript":
      return <JavascriptLogo className={className} />;
    default:
      null;
  }
}
