type Props = {
  className?: string;
};

export default function Logo({
  className = "h-10 w-10 md:h-16 md:w-16",
}: Props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.999 511.999"
      fill="#000000"
      className={className}
    >
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          fill="#8CE16E"
          d="M459.034,110.345c0-24.376-19.762-44.138-44.138-44.138h-29.195c-7.977,0-14.558-5.508-16.927-13.126 c-5.589-17.967-22.348-31.012-42.154-31.012H185.379c-24.376,0-44.138,19.762-44.138,44.138v105.931h317.793V110.345z"
        ></path>
        <path
          fill="#7DC86E"
          d="M423.724,154.482c0-24.376-19.762-44.138-44.138-44.138h-23.023 c-10.988,0-20.302-7.061-24.583-17.181c-6.701-15.842-22.388-26.957-40.67-26.957H150.069c-3.023,0-5.975,0.31-8.828,0.889V128 H97.103v70.621c0,24.376,19.762,44.138,44.138,44.138h132.414v-70.621h150.069V154.482z"
        ></path>
        <path
          fill="#785353"
          d="M300.138,233.931v44.138c0,14.603-11.88,26.483-26.483,26.483h-8.828v-79.448h-17.655v44.138h-44.138 c-14.603,0-26.483-11.88-26.483-26.483v-52.966h-17.655v52.966c0,24.337,19.801,44.138,44.138,44.138h44.138v194.207h17.655V322.207 h8.828c24.337,0,44.138-19.801,44.138-44.138v-44.138H300.138z"
        ></path>
        <g>
          <rect
            x="158.896"
            y="189.793"
            fill="#643C3E"
            width="17.655"
            height="26.483"
          ></rect>
          <rect
            x="247.172"
            y="225.103"
            fill="#643C3E"
            width="17.655"
            height="35.31"
          ></rect>
          <rect
            x="300.138"
            y="233.931"
            fill="#643C3E"
            width="17.655"
            height="26.483"
          ></rect>
        </g>
        <path
          fill="#8CE16E"
          d="M317.793,489.931H194.207c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h123.586 c4.879,0,8.828,3.953,8.828,8.828S322.672,489.931,317.793,489.931z"
        ></path>
        <g>
          <path
            fill="#9BF57D"
            d="M227.62,66.207H105.931c-19.806,0-36.565,13.046-42.154,31.012 c-2.369,7.617-8.95,13.126-16.927,13.126h-0.815c-23.052,0-43.639,16.854-45.827,39.802c-2.51,26.325,18.123,48.473,43.93,48.473 h123.586c19.806,0,36.565-13.046,42.154-31.012c2.369-7.616,8.951-13.126,16.927-13.126h2.712c25.807,0,46.441-22.148,43.93-48.473 C271.259,83.06,250.672,66.207,227.62,66.207z"
          ></path>
          <path
            fill="#9BF57D"
            d="M465.965,110.345h-86.379c-19.806,0-36.565,13.046-42.154,31.012 c-2.369,7.616-8.951,13.126-16.927,13.126h-44.953c-23.052,0-43.639,16.854-45.827,39.802c-2.51,26.325,18.123,48.473,43.93,48.473 h88.276c19.806,0,36.565-13.046,42.154-31.012c2.369-7.616,8.951-13.126,16.927-13.126h46.85c25.807,0,46.441-22.148,43.931-48.473 C509.604,127.198,489.017,110.345,465.965,110.345z"
          ></path>
        </g>
        <g>
          <path
            fill="#8CE16E"
            d="M88.483,150.146c2.188-22.948,22.775-39.801,45.827-39.801h0.815c7.977,0,14.558-5.51,16.927-13.126 c5.589-17.967,22.348-31.012,42.154-31.012h-88.276c-19.806,0-36.565,13.045-42.154,31.012 c-2.369,7.616-8.951,13.126-16.927,13.126h-0.815c-23.053,0-43.639,16.853-45.827,39.801c-2.51,26.326,18.123,48.474,43.93,48.474 h88.276C106.606,198.62,85.973,176.472,88.483,150.146z"
          ></path>
          <path
            fill="#8CE16E"
            d="M273.862,194.284c2.188-22.948,22.775-39.801,45.827-39.801h-44.138 c-23.053,0-43.639,16.853-45.827,39.801c-2.51,26.326,18.123,48.474,43.93,48.474h44.138 C291.985,242.758,271.352,220.61,273.862,194.284z"
          ></path>
          <path
            fill="#8CE16E"
            d="M381.57,141.357c5.589-17.967,22.348-31.012,42.154-31.012h-44.138 c-19.806,0-36.565,13.045-42.154,31.012c-2.369,7.616-8.951,13.126-16.927,13.126h44.138 C372.62,154.482,379.201,148.973,381.57,141.357z"
          ></path>
        </g>
      </g>
    </svg>
  );
}
