export function removeAccents(text: string) {
  const vowelsWithAccent = ["á", "é", "í", "ó", "ú"];
  const vowelsWithoutAccent = ["a", "e", "i", "o", "u"];

  const result = text
    .toLowerCase()
    .split("")
    .map((letter) => {
      const index = vowelsWithAccent.indexOf(letter);
      return index !== -1 ? vowelsWithoutAccent[index] : letter;
    })
    .join("");

  return result;
}
