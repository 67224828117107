import { useEffect, type ReactNode } from "react";
import SearchBox from "@/components/SearchBox";
import SwitchLang from "@/components/SwitchLang";
import Footer from "@/components/Layout/Footer";
import BackButton from "../BackButton";

type Props = {
  children: ReactNode;
};

function PageLayout({ children }: Props) {
  return (
    <div className="relative m-auto flex min-h-screen max-w-screen-2xl flex-col">
      <SwitchLang />
      <SearchBox />
      <BackButton />
      {/* <div className="ads:grid ads:grid-cols-12 ads:gap-4 ads:p-4 relative">
        <div className="ads:flex sticky top-10 col-span-1 mr-auto  hidden h-96 w-28 items-center justify-center border-4 border-gray-600 bg-gray-500 text-center font-bold text-gray-600">
          AD
        </div> */}

      <div className=" col-span-10 mx-auto flex h-full w-full max-w-screen-2xl flex-col justify-center lg:px-8">
        {children}
      </div>

      {/*  <div className="ads:flex sticky right-2 top-10 col-span-1 ml-auto  hidden h-96 w-28 items-center justify-center border-4 border-gray-600 bg-gray-500 text-center font-bold text-gray-600">
          AD
        </div>
      </div> */}

      <Footer />
    </div>
  );
}

export default PageLayout;
