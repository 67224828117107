import Link from "next/link";
import { useRouter } from "next/router";
import Logo from "./Logo";

function BackButton() {
  const { locale } = useRouter();

  return (
    <Link
      href="/"
      aria-label={locale === "en" ? "Go home" : "Ir al inicio"}
      className="group absolute left-4 top-4 hover:cursor-pointer"
    >
      <Logo />
    </Link>
  );
}

export default BackButton;
